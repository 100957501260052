import React from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from "react-toastify";

import StepHeader from './StepHeader';
import OnboardingProgress from 'components/stats/OnboardingProgress';
import OnboardingWrapper from '../index';
import ImportingData from 'components/stats/ImportingData';

type AccountIngestProps = {
    AuthStore?: any;
    OnboardingStore?: any;
}

class AccountIngest extends React.Component<AccountIngestProps, {}> {
    state = {
        firstRender: true,
        connected: false
    }

    componentDidMount() {
        const { AuthStore } = this.props;
        const { firstRender } = this.state;

        if (firstRender) {
            const searchParams = new URL(window.location.href).searchParams;
            const code = searchParams.get('code');
            const error = searchParams.get('error');

            if (code || error) {
                this.setState({ firstRender: false }, () => {
                    AuthStore.oauth({ code, error })
                        .then(() => this.setState({ connected: true }))
                        .catch(() => toast.error(`oAuth error.`, { icon: false }));        
                });
            } else {
                this.setState({ 
                    firstRender: false,
                    connected: true
                });
            }
        }
    }

    onClick = () => {
        const { OnboardingStore } = this.props;

        OnboardingStore.stopPollingForStats();
        OnboardingStore.completeOnboarding()
            .then(() => {
                window.location.href = '/dashboard'
            });
    }

    render() {
        const { connected } = this.state;

        return (
            <OnboardingWrapper>
                <StepHeader currentStep={5} />
                <div className="flex-1"></div>
                {connected ? <OnboardingProgress buttonText='Go to Tutorial' onClick={this.onClick} /> : <ImportingData />}
                <div className="flex-1"></div>
            </OnboardingWrapper>
        );
    }
}

export default inject("AuthStore", "OnboardingStore")(observer(AccountIngest));