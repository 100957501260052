import React from 'react';
import { inject, observer } from 'mobx-react';

import { LogoutIcon } from 'components/common/Icon';
import withPostHog from "hooks/withPosthog";

import './FloatingLogout.scss';

interface FloatingLogoutProps {
    AuthStore?: any;
    userEvents?: any;
}

class FloatingLogout extends React.Component<FloatingLogoutProps, any> {
    onClick = () => {
        const { AuthStore, userEvents } = this.props;

        userEvents?.posthog.capture("onboaring-logout", { });
        
        AuthStore.logout()
            .then(() => {
                if (userEvents) {
                    userEvents?.posthog.reset();
                }

                window.location.href = '/';
            });
    }
    
    render() {
        return (
            <div className="adapter-floating-logout-button" onClick={this.onClick}>
                <LogoutIcon title="Logout" />
            </div>
        );
    }
}

export default withPostHog(inject('UserStore', 'AuthStore')(observer(FloatingLogout)));