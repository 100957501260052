import React from 'react';

const Outlook = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="30" viewBox="0 0 34 30" fill="none">
        <rect x="10.7695" y="0.460938" width="20.7692" height="29.0769" rx="1.38462" fill="#9093A2" />
        <rect x="10.7695" y="0.460938" width="20.7692" height="29.0769" rx="1.38462" fill="#9093A2" />
        <rect x="10.7695" y="3.57812" width="10.3846" height="10.3846" fill="#9093A2" />
        <rect x="10.7695" y="13.9609" width="10.3846" height="10.3846" fill="#9093A2" />
        <rect x="21.1543" y="13.9609" width="10.3846" height="10.3846" fill="#9093A2" />
        <rect x="21.1543" y="3.57812" width="10.3846" height="10.3846" fill="#9093A2" />
        <mask id="mask0_6188_2462" style={{ maskType:"alpha" }} maskUnits="userSpaceOnUse" x="8" y="12" width="26" height="18">
            <path d="M8.69238 12.9219H32.2308C32.9955 12.9219 33.6155 13.5418 33.6155 14.3065V28.1526C33.6155 28.9173 32.9955 29.5373 32.2308 29.5373H10.077C9.3123 29.5373 8.69238 28.9173 8.69238 28.1526V12.9219Z" fill="url(#paint0_linear_6188_2462)" />
        </mask>
        <g mask="url(#mask0_6188_2462)">
            <path d="M33.615 12.9219V17.0757H31.5381V12.9219H33.615Z" fill="#9093A2" />
            <path d="M33.6152 29.5385V15L7.6537 29.5385H33.6152Z" fill="#9093A2" />
            <path d="M8.69238 29.5385V15L34.6539 29.5385H8.69238Z" fill="#9093A2" />
        </g>
        <path d="M8.69238 9.80739C8.69238 8.66034 9.62225 7.73047 10.7693 7.73047H19.077C20.2241 7.73047 21.1539 8.66034 21.1539 9.80739V24.3459C21.1539 25.4929 20.2241 26.4228 19.077 26.4228H8.69238V9.80739Z" fill="black" fillOpacity="0.3" />
        <rect x="0.384766" y="5.65234" width="18.6923" height="18.6923" rx="1.38462" fill="#9093A2" />
        <path d="M14.9237 15.0728V14.9002C14.9237 11.9085 12.7712 9.80859 9.7478 9.80859C6.708 9.80859 4.53906 11.9229 4.53906 14.929V15.1016C4.53906 18.0933 6.69157 20.1932 9.73137 20.1932C12.7547 20.1932 14.9237 18.0789 14.9237 15.0728ZM12.4754 15.1016C12.4754 17.0865 11.3581 18.2803 9.7478 18.2803C8.13753 18.2803 7.00377 17.0577 7.00377 15.0728V14.9002C7.00377 12.9153 8.1211 11.7215 9.73137 11.7215C11.3252 11.7215 12.4754 12.9441 12.4754 14.929V15.1016Z" fill="white" />
        <defs>
            <linearGradient id="paint0_linear_6188_2462" x1="8.69238" y1="26.1822" x2="33.6155" y2="26.1822" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1B366F" />
                <stop offset="1" stopColor="#2657B0" />
            </linearGradient>
        </defs>
    </svg>
);

export default Outlook;