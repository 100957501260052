import React from 'react';

import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import { AlertCircleIcon, CloseIcon } from 'components/common/Icon';

const PrivacyPolicyModal = ({ onClose }) => (
    <Modal onClose={onClose}>
        <div className="onboarding-modal">
            <div className="onboarding-modal-head">
                <AlertCircleIcon color="#5C33CC" />
                <CloseIcon
                    onClick={onClose}
                    style={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0 }}
                />
            </div>

            <div className="onboarding-modal-body" style={{ minHeight: '350px', display: 'flex' }}>
                <iframe src="https://www.adapter.com/tos" />
            </div>

            <div className="onboarding-modal-foot">
                <Button onClick={onClose}>Close</Button>
            </div>
        </div>
    </Modal>
);

export default PrivacyPolicyModal;