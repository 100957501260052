import React from 'react';

import { Text, HeadText } from '../../Typography';

export const StartTaskImg = ({ cls }) => (
    <img src="/tour.starttask.gif" alt="Start a task or ask a question" className={cls} />
);

export const StartTaskContent = ({ cls }) => {
    return (
        <div className={`flex flex-col items-center ${cls}`}>
            <HeadText fontSize='xs'>Start a Task or Ask a Question</HeadText>

            <Text fontSize='s' fontWeight='light' cls='mt-4 mb-8 text-center'>
                Use the toggle to switch between booking reservations and learning about your habits. 
                Just enter your request in plain language or use the premade prompts cards to get started.
            </Text>
        </div>
    );
}