import React from 'react';

import { Text, HeadText } from '../../Typography';

export const SmartTaskImg = ({ cls }) => (
    <img src="/tour.smarttask.gif" alt="Try a Smart Task" className={cls} />
);

export const SmartTaskContent = ({ cls }) => {
    return (
        <div className={`flex flex-col items-center ${cls}`}>
            <HeadText fontSize='xs'>Try a Smart Task</HeadText>

            <Text fontSize='s' fontWeight='light' cls='mt-4 mb-8 text-center'>
                Adapter will make suggestions based on your preferences and schedule. 
                The more accounts you connect or memories you add, the better the suggestions.
            </Text>
        </div>
    );
}