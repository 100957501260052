import React from 'react';

import Spinner from './Spinner';

import './Loading.scss';

const Loading = ({ modal = true, style = {} }) => (
    modal
        ? (
            <div className="adapter-modal" style={style}>
                <div className="adapter-loading">
                    <Spinner />
                </div>
            </div>
        )
        : (
            <div className="adapter-loading" style={style}>
                <Spinner />
            </div>
        )


);

export default Loading;