import React from 'react';

import Card from '../Card';
import './Modal.scss';

const Modal = ({ children, cls = '', onClose = () => {} }: any) => (
    <div className={`adapter-modal ${cls}`}>
        <div className="adapter-modal-background" onClick={onClose} />
        <div className="adapter-modal-content">
            <Card>
                {children}
            </Card>
        </div>
    </div>
);

export default Modal;