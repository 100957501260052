import { useState } from 'react';

import { Text } from 'components/common/Typography';

import './MatchComparisonBar.scss';

type MatchComparisonBarProps = {
    score: number;
    allScores: number[];
    tooltipFormatter?: (score: number) => string;
};

const MatchComparisonBar = ({
    score,
    allScores,
    tooltipFormatter = (score) => `Match Score: ${(score * 100).toFixed(0)}%`
  }: MatchComparisonBarProps) => {
    const [activeTooltip, setActiveTooltip] = useState(null);
    
    if (!allScores || allScores.length === 0) {
      return null;
    }
  
    const sortedScores = [...allScores].sort((a, b) => a - b);
    const maxScore = Math.max(...allScores);
    const minScore = Math.min(...allScores);
  
    // Normalize position on bar
    const normalizeValue = (value: number) => {
      if (allScores.length === 1) {
        return value * 100;
      }
      
      if (maxScore === minScore) return 50; // Center it if all scores are the same
      return ((value - minScore) / (maxScore - minScore) * 100);
    };

    const otherScores = allScores.filter(s => s !== score);

    return (
        <div className="matchComparisonBarContainer">
            <Text fontWeight='bold' fontSize='l' cls="utilityBarTitle">Match Comparison:</Text>
            <Text cls="subTitle" fontSize='s'>
                {allScores.length === 1 
                    ? "How well this option matches your request"
                    : "How this option compared to the other results found"
                }
            </Text>
            
            <div className="utilityBar">
                {otherScores.map((otherScore, index) => {
                    const left = `${normalizeValue(otherScore)}%`;
                    return (
                        <div 
                            className="dotContainer" 
                            key={index} 
                            style={{ left }}
                        >
                            <div
                                className="dot"
                                onMouseEnter={() => setActiveTooltip(index)}
                                onMouseLeave={() => setActiveTooltip(null)}
                            />
                            {activeTooltip === index && (
                                <div className="scoreTooltip">
                                    <div className="scoreValue">
                                        {tooltipFormatter(otherScore)}
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}

                <div 
                    className="dotContainer" 
                    style={{ left: `${normalizeValue(score)}%` }}
                >
                    <div
                        className="dot highlighted"
                        onMouseEnter={() => setActiveTooltip(allScores.length)}
                        onMouseLeave={() => setActiveTooltip(null)}
                    />
                    {activeTooltip === allScores.length && (
                        <div className="scoreTooltip">
                            <div className="scoreValue">
                                {tooltipFormatter(score)}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="labelContainer">
                <span className="label">Lower Match</span>
                <span className="label">Better Match</span>
            </div>
        </div>
    );
};

export default MatchComparisonBar;