import React from 'react';
import { inject, observer } from 'mobx-react';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'
import { toast } from "react-toastify";
import { Text, HeadText } from 'components/common/Typography';

import Progress from 'components/common/Progress';

type AccountStatsProps = {
    UserStore?: any;
    accountEmail: string;
}

type AccountStatsState = {
    totalEmails: number;
    processedEmails: number;
    flightExtractions: number;
    hotelStayExtractions: number;
    hasLoaded: boolean;
}

class AccountStats extends React.Component<AccountStatsProps, AccountStatsState> {
    state = {
        flightExtractions: 0,
        hotelStayExtractions: 0,
        processedEmails: 0,
        totalEmails: 0,
        hasLoaded: false
    };

    componentDidMount(): void {
        const { UserStore, accountEmail } = this.props;

        UserStore.getAccountData(accountEmail)
            .then(({ flightExtractions, hotelStayExtractions, processedEmails, totalEmails }) => {
                this.setState({
                    flightExtractions,
                    hotelStayExtractions,
                    processedEmails,
                    totalEmails,
                    hasLoaded: true
                });
            })
            .catch(() => {
                toast.error(
                    (
                        <div>Adapter encountered a problem retreiving your accoun data.</div>
                    ),
                    { theme: 'dark', position: 'bottom-right', icon: true }
                );
            });
    }

    render() {
        const {
            flightExtractions,
            hotelStayExtractions,
            processedEmails,
            totalEmails,
            hasLoaded
        } = this.state;

        return (
            <div className="onboarding-stats mt-5">
                {!hasLoaded && (
                    <Progress />
                )}
                
                {hasLoaded && (
                    <>
                        <div className="onboarding-progress">
                            <CircularProgress
                                capIsRound
                                thickness='6px'
                                size='150px'
                                max={totalEmails}
                                value={processedEmails}
                                color='#7F56D9'
                            >
                                <CircularProgressLabel>
                                    <HeadText>
                                        {processedEmails}
                                    </HeadText>

                                    <Text>Out of {` ${totalEmails}`}</Text>
                                </CircularProgressLabel>
                            </CircularProgress>
                        </div>

                        <div className="onboarding-text-container">
                            <div className="onboarding-text-row">
                                <Text>Flight reservations ingested</Text>
                                <Text>{flightExtractions}</Text>
                            </div>

                            <div className="onboarding-text-row">
                                <Text>Hotel reservations ingested</Text>
                                <Text>{hotelStayExtractions}</Text>
                            </div>

                            <div className="onboarding-text-row">
                                <Text>Total emails processed</Text>
                                <Text>{processedEmails}</Text>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default inject("UserStore")(observer(AccountStats));