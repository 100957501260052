import React from 'react';

import { Text, HeadText } from '../../Typography';

export const AddMemoryImg = ({ cls }) => (
    <img src="/tour.addmemory.gif" alt="Add a Memory" className={cls} />
);

export const AddMemoryContent = ({ cls }) => {
    return (
        <div className={`flex flex-col items-center ${cls}`}>
            <HeadText fontSize='xs'>Teach Adapter</HeadText>

            <Text fontSize='s' fontWeight='light' cls='mt-4 mb-8 text-center'>
                Get better results and launch tasks faster by adding important information to 
                Adapter. Tell Adapter to &quot;remember&quot; your favorite food, your best 
                friend&apos;s address, or even a great gift idea.
            </Text>
        </div>
    );
}