import React from 'react';

import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import { GoogleIcon, CloseIcon } from 'components/common/Icon';

const GrantDataAccess = ({ onClose, onContinue }) => (
    <Modal onClose={onClose}>
        <div className="onboarding-modal">
            <div className="onboarding-modal-head">
                <GoogleIcon />
                <CloseIcon
                    onClick={onClose}
                    style={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0 }}
                />
            </div>

            <div className="onboarding-modal-body">
                <img src="/data-access-google.svg" alt="Grant Data Access" style={{ margin: '0 auto 40px auto' }} />

                <h3>Granting Data Access</h3>

                <p>
                    You will be directed to a Google account page to grant data permissions. Enter the email account 
                    you would like to connect and select &quot;Allow&quot;. These are needed for Adapter to retrieve your data. 
                </p>

                <p>
                    After connecting an account, you&apos;ll be able to specify what categories of data you want Adapter to import.
                </p>
                
            </div>

            <div className="onboarding-modal-foot">
                <Button onClick={onContinue}>Go to Google Permissions</Button>
            </div>
        </div>
    </Modal>
);

export default GrantDataAccess;