import React from 'react';

const ThumbsUp = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M5.83341 18.3327V9.16602M1.66675 10.8327V16.666C1.66675 17.5865 2.41294 18.3327 3.33341 18.3327H14.522C15.7559 18.3327 16.8053 17.4324 16.9929 16.2128L17.8903 10.3795C18.1233 8.8651 16.9516 7.49935 15.4194 7.49935H12.5001C12.0398 7.49935 11.6667 7.12625 11.6667 6.66602V3.72088C11.6667 2.58601 10.7468 1.66602 9.61188 1.66602C9.3412 1.66602 9.0959 1.82543 8.98596 2.07278L6.05336 8.67113C5.91961 8.97207 5.62118 9.16602 5.29185 9.16602H3.33341C2.41294 9.16602 1.66675 9.91221 1.66675 10.8327Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ThumbsUp;