import React from 'react';
import { inject, observer } from 'mobx-react';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'

import Card from "components/common/Card";
import { Text, HeadText } from 'components/common/Typography';

import './OnboardingStats.scss';

type OnboardingStatsProps = {
    OnboardingStore?: any;
}

class OnboardingStats extends React.Component<OnboardingStatsProps, {}> {
    componentDidMount(): void {
        const { OnboardingStore } = this.props;
        OnboardingStore.pollForStats(() => {
            window.location.href = '/onboarding/details';
        });
    }
    
    render() {
        const { OnboardingStore } = this.props;

        return (
            <Card cls='responsive-card' style={{ margin: 'auto' }}>
                <HeadText>
                    Processing Inbox...
                </HeadText>

                <div className="onboarding-stats">
                    <div className="onboarding-progress">
                        <CircularProgress
                            capIsRound
                            thickness='6px' 
                            size='150px' 
                            max={OnboardingStore.totalEmails} 
                            value={OnboardingStore.processedEmails} 
                            color='#7F56D9'
                        >
                            <CircularProgressLabel>
                                <HeadText>
                                    {OnboardingStore.processedEmails}
                                </HeadText>

                                <Text>Out of {` ${OnboardingStore.totalEmails}`}</Text>
                            </CircularProgressLabel>
                        </CircularProgress>
                    </div>

                    <div className="onboarding-text-container">
                        <div className="onboarding-text-row">
                            <Text>Flight reservations ingested</Text>
                            <Text>{OnboardingStore.flightExtractions}</Text>
                        </div>

                        <div className="onboarding-text-row">
                            <Text>Hotel reservations ingested</Text>
                            <Text>{OnboardingStore.hotelStayExtractions}</Text>
                        </div>

                        <div className="onboarding-text-row">
                            <Text>Total emails processed</Text>
                            <Text>{OnboardingStore.processedEmails}</Text>
                        </div>
                    </div>
                </div>
            </Card>
        );
    }
}

export default inject("OnboardingStore")(observer(OnboardingStats));