import React from 'react';

import { Text, HeadText } from '../../Typography';

export const FeedbackImg = ({ cls }) => (
    <img src="/tour.feedback.png" alt="Provide feedback" className={cls} />
);

export const FeedbackContent = ({ cls }) => {
    return (
        <div className={`flex flex-col items-center ${cls}`}>
            <HeadText fontSize='xs'>Leave Feedback</HeadText>

            <Text fontSize='s' fontWeight='light' cls='mt-4 mb-8 text-center'>
                Not satisfied with your result? Have an idea for a great new feature? 
                Let us know by clicking on the feedback buttons or adding a comment.
            </Text>
        </div>
    );
}