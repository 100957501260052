import React from 'react';

const Location = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M9.99992 10.8327C11.3806 10.8327 12.4999 9.71339 12.4999 8.33268C12.4999 6.95197 11.3806 5.83268 9.99992 5.83268C8.61921 5.83268 7.49992 6.95197 7.49992 8.33268C7.49992 9.71339 8.61921 10.8327 9.99992 10.8327Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M9.99992 18.3327C13.3333 14.9993 16.6666 12.0146 16.6666 8.33268C16.6666 4.65078 13.6818 1.66602 9.99992 1.66602C6.31802 1.66602 3.33325 4.65078 3.33325 8.33268C3.33325 12.0146 6.66659 14.9993 9.99992 18.3327Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Location;