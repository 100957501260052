import React, { ChangeEvent } from "react";
import { Link } from "react-router-dom";

import './Input.scss';

export const validateInputs = (value = '') => {
    //@ts-ignore
    let parts = value.split('-');
    
    const values = {
        MM: { value: undefined, error: false },
        DD: { value: undefined, error: false },
        YYYY: { value: undefined, error: false },
    };

    for (let i=0; i<parts.length; i++) {
        const num = parseInt(parts[i]);
        
        // month
        if (i === 0) {
            values.MM = {
                value: num,
                error: isNaN(num) || num < 1 || num > 12
            };
        }
        // day
        else if (i === 1) {
            values.DD = {
                value: num,
                error: isNaN(num) || num < 1 || num > 31
            };
        }
        // year
        else if (i === 2) {
            const currentYear = new Date().getFullYear();
            values.YYYY = {
                value: num,
                error: isNaN(num) || num < currentYear - 125 || num > currentYear - 2
            };
        }
    }

    return values
}

export type DateInputProps = {
    labelText?: string | any;
    fieldId: string;

    value?: string | number;
    hintText?: string;
    disabled?: boolean;
    required?: boolean;

    errorText?: string;
    error?: boolean;

    link?: {
        linkText: string;
        linkUrl: string;
    };

    onChange: (event: ChangeEvent<any>) => void;

    style?: any;
    darkMode?: boolean;
};

export default class DateInput extends React.Component<DateInputProps, {}> {
    onChangeHandler = (e: any) => {
        const { fieldId, onChange } = this.props;
        const { MM, DD, YYYY } = this.validateInputs();

        // from one of the internal fields
        const { value, name } = e.target;

        let data = Object.assign({}, { MM: MM.value, DD: DD.value, YYYY: YYYY.value }, { [name]: value });

        // @ts-ignore
        onChange({ 
            target: { 
                name: fieldId, 
                value: `${data.MM}-${data.DD}-${data.YYYY}`
            } 
        });
    }

    validateInputs = () => {
        const { value = '' } = this.props;

        // @ts-ignore
        return validateInputs(value);
    }

    renderTextInput = () => {
        const { disabled, errorText } = this.props;

        const { MM, DD, YYYY } = this.validateInputs();

        return (
            <div className="flex flex-row" style={{ height: '50px', gap: '9px' }}>
                <input
                    type="number"
                    placeholder="MM"
                    id="MM"
                    name="MM"
                    value={MM.value}
                    disabled={disabled}
                    onChange={this.onChangeHandler}
                    className={errorText ? 'error' : ''}
                />
                <input
                    type="number"
                    placeholder="DD"
                    id="DD"
                    name="DD"
                    value={DD.value}
                    disabled={disabled}
                    onChange={this.onChangeHandler}
                    className={errorText ? 'error' : ''}
                />
                <input
                    type="number"
                    placeholder="YYYY"
                    id="YYYY"
                    name="YYYY"
                    value={YYYY.value}
                    disabled={disabled}
                    onChange={this.onChangeHandler}
                    className={errorText ? 'error' : ''}
                />
            </div>
        );
    }

    render() {
        const {
            fieldId,
            hintText,
            errorText,
            labelText,
            link,
            style = {},
            darkMode = false
        } = this.props;

        return (
            <div className={`adapter-form-field-input DateInput ${darkMode ? 'darkMode' : ''}`} style={style}>
                {labelText && (
                    <label htmlFor={fieldId}>
                        {labelText}
                    </label>
                )}

                {this.renderTextInput()}

                {link && (
                    <div className="text-sm text-right" style={{ width: '100%' }}>
                        <Link className="font-semibold" to={link.linkUrl}>
                            {link.linkText}
                        </Link>
                    </div>
                )}

                {!errorText && hintText && (
                    <p className="hintText">{hintText}</p>
                )}

                {errorText && (
                    <p className="errorText">{errorText || 'MM-DD-YYYY must all be numbers'}</p>
                )}
            </div>
        );
    }
}