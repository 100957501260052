import React from 'react';

import Adapter from './icons/Adapter';
import AlertCircle from './icons/AlertCircle';
import Announcement from './icons/Announcement';
import Atlas from './icons/Atlas';
import CaretRight from './icons/CaretRight';
import Close from './icons/Close';
import Compass from './icons/Compass';
import EyeClosed from './icons/EyeClosed';
import EyeOpen from './icons/EyeOpen';
import Globe from './icons/Globe';
import Home from './icons/Home';
import Hotel from './icons/Hotel';
import Intentions from './icons/Intentions';
import Lightbulb from './icons/Lightbulb';
import LinkBroken from './icons/LinkBroken';
import Location from './icons/Location';
import Logout from './icons/Logout';
import Martini from './icons/Martini';
import Menu from './icons/Menu';
import Mirror from './icons/Mirror';
import Plane from './icons/Plane';
import PurpleCircle from './icons/PurpleCircle';
import QuestionCircle from './icons/QuestionCircle';
import Send from './icons/Send';
import Settings from './icons/Settings';
import Star from './icons/Star';
import Support from './icons/Support';
import Target from './icons/Target';
import ThumbsUp from './icons/ThumbsUp';
import Trash from './icons/Trash';
import Upload from './icons/Upload';
import User from './icons/User';

import Apple from './icons/thirdparty/Apple';
import Facebook from './icons/thirdparty/Facebook';
import Google from './icons/thirdparty/Google';
import LinkedIn from './icons/thirdparty/LinkedIn';
import Outlook from './icons/thirdparty/Outlook';
import X from './icons/thirdparty/X';

import './Icon.scss';

type IconProps = {
    IconComponent: any;
    title: string;
    color?: string;
    style?: any;
    onClick?: () => {}
};

const Icon = ({ IconComponent, title, color, onClick, style = {} }: IconProps) => (
    <span title={title} className="adapter-icon" onClick={onClick} style={style}>
        <IconComponent color={color} />
    </span>
);

export default Icon;

export const AdapterIcon = (props: any) => <Icon IconComponent={Adapter} {...props} />;
export const AlertCircleIcon = (props: any) => <Icon IconComponent={AlertCircle} {...props} />;
export const AnnouncementIcon = (props: any) => <Icon IconComponent={Announcement} {...props} />;
export const AppleIcon = (props: any) => <Icon IconComponent={Apple} {...props} />;
export const AtlasIcon = (props: any) => <Icon IconComponent={Atlas} {...props} />;
export const CaretRightIcon = (props: any) => <Icon IconComponent={CaretRight} {...props} />;
export const CloseIcon = (props: any) => <Icon IconComponent={Close} {...props} />;
export const CompassIcon = (props: any) => <Icon IconComponent={Compass} {...props} />;
export const EyeClosedIcon = (props: any) => <Icon IconComponent={EyeClosed} {...props} />;
export const EyeOpenIcon = (props: any) => <Icon IconComponent={EyeOpen} {...props} />;
export const FacebookIcon = (props: any) => <Icon IconComponent={Facebook} {...props} />;
export const GlobeIcon = (props: any) => <Icon IconComponent={Globe} {...props} />;
export const GoogleIcon = (props: any) => <Icon IconComponent={Google} {...props} />;
export const HomeIcon = (props: any) => <Icon IconComponent={Home} {...props} />;
export const HotelIcon = (props: any) => <Icon IconComponent={Hotel} {...props} />;
export const IntentionsIcon = (props: any) => <Icon IconComponent={Intentions} {...props} />;
export const LightbulbIcon = (props: any) => <Icon IconComponent={Lightbulb} {...props} />;
export const LinkBrokenIcon = (props: any) => <Icon IconComponent={LinkBroken} {...props} />;
export const LinkedInIcon = (props: any) => <Icon IconComponent={LinkedIn} {...props} />;
export const LocationIcon = (props: any) => <Icon IconComponent={Location} {...props} />;
export const LogoutIcon = (props: any) => <Icon IconComponent={Logout} {...props} />;
export const MartiniIcon = (props: any) => <Icon IconComponent={Martini} {...props} />;
export const MenuIcon = (props: any) => <Icon IconComponent={Menu} {...props} />;
export const MirrorIcon = (props: any) => <Icon IconComponent={Mirror} {...props} />;
export const OutlookIcon = (props: any) => <Icon IconComponent={Outlook} {...props} />;
export const PurpleCircleIcon = (props: any) => <Icon IconComponent={PurpleCircle} {...props} />;
export const PlaneIcon = (props: any) => <Icon IconComponent={Plane} {...props} />;
export const QuestionCircleIcon = (props: any) => <Icon IconComponent={QuestionCircle} {...props} />;
export const SendIcon = (props: any) => <Icon IconComponent={Send} {...props} />;
export const SettingsIcon = (props: any) => <Icon IconComponent={Settings} {...props} />;
export const StarIcon = (props: any) => <Icon IconComponent={Star} {...props} />;
export const SupportIcon = (props: any) => <Icon IconComponent={Support} {...props} />;
export const TargetIcon = (props: any) => <Icon IconComponent={Target} {...props} />;
export const ThumbsUpIcon = (props: any) => <Icon IconComponent={ThumbsUp} {...props} />;
export const TrashIcon = (props: any) => <Icon IconComponent={Trash} {...props} />;
export const UploadIcon = (props: any) => <Icon IconComponent={Upload} {...props} />;
export const UserIcon = (props: any) => <Icon IconComponent={User} {...props} />;
export const XIcon = (props: any) => <Icon IconComponent={X} {...props} />;