import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from "react-toastify";

import StepHeader from './StepHeader';
import SafeguardsModal from '../modals/SafeguardsModal';
import DataHandlingModal from '../modals/DataHandlingModal';
import { GoogleIcon, CaretRightIcon, OutlookIcon, AppleIcon, LinkedInIcon, FacebookIcon, XIcon } from 'components/common/Icon';
import OnboardingWrapper from '../index';
import { Text, HeadText, LINK } from 'components/common/Typography';
import Button from 'components/common/Button';
import { usePostHog } from 'posthog-js/react';

const Connect = ({ OnboardingStore }: { OnboardingStore?: any }) => {
    const [ safeguardsOpen, setSafeguardsOpen ] = useState(false);
    const [ dataHandlingOpen, setDataHandlingOpen ] = useState(false);

    const toastOptions = {
        theme: 'dark',
        position: 'bottom-right',
        icon: false 
    };

    const posthog = usePostHog();

    const requestThirdParty = (vendor) => {
       
        OnboardingStore.requestVendor(vendor)
            .then(() => {
                // @ts-ignore
                toast.success("Thank you for logging a connection request, we will let you know when it's available.", toastOptions);
                posthog.capture("vendor requested", { request: vendor });
            })
            .catch(() => {
                // @ts-ignore
                toast.error("Failed to send request. Please contact customer support if you continue to encounter problems.", toastOptions);
            });
    };

    return (
        <OnboardingWrapper>
            <StepHeader currentStep={3} />

            <div className="adapter-two-col">

                <div className="adapter-two-col--title">
                    <div className="flex-1"></div>

                    <HeadText cls='adapter-font-color-primary-900' fontWeight='bold'>
                        Connect an Account
                    </HeadText>

                    <Text>
                        Adapter lets you retrieve your user data from major platforms so that it can be used to provide you
                        with powerful insights and personalized outcomes. Connecting an account brings your historical data
                        into Adapter and allows Adapter to retrieve new pieces of data when they are created.
                    </Text>

                    <Text>
                        You get to choose what categories of data you want Adapter to import from an account.
                    </Text>

                    <Text>
                        <LINK onClick={() => setSafeguardsOpen(true)}>Learn how Adapter safeguards your data</LINK>
                    </Text>

                    <Text>
                        <LINK onClick={() => setDataHandlingOpen(true)}>Learn what happens to your account data</LINK>
                    </Text>

                    <div className="flex-1"></div>
                </div>

                <div className="adapter-two-col--content">
                    <div className="flex-1"></div>

                    <div className="connect-account-option">
                        <div><GoogleIcon /></div>
                         <div className="flex-1" onClick={() => { window.location.href = '/onboarding/controls'; }} style={{ cursor: 'pointer' }}>
                            <Text>Google Mail &amp; Calendar</Text>
                         </div>
                         <div><CaretRightIcon /></div>
                    </div>

                    <h3 className="connect-account-title">Coming Soon...</h3>

                    <div className="connect-account-option coming-soon">
                        <div><XIcon /></div>
                         <div className="flex-1">
                            <Text>X</Text>
                         </div>
                         <div>
                            <Button cls='plain' size='small' onClick={()=>requestThirdParty('x')}>Request</Button>
                        </div>
                    </div>

                    <div className="connect-account-option coming-soon">
                        <div><OutlookIcon /></div>
                         <div className="flex-1">
                            <Text>Microsoft Outlook</Text>
                         </div>
                         <div>
                            <Button cls='plain' size='small' onClick={()=>requestThirdParty('outlook')}>Request</Button>
                        </div>
                    </div>

                    <div className="connect-account-option coming-soon">
                        <div><AppleIcon /></div>
                         <div className="flex-1">
                            <Text>iCloud</Text>
                         </div>
                         <div>
                            <Button cls='plain' size='small' onClick={()=>requestThirdParty('icloud')}>Request</Button>
                        </div>
                    </div>

                    <div className="connect-account-option coming-soon">
                        <div><FacebookIcon /></div>
                         <div className="flex-1">
                            <Text>Facebook</Text>
                         </div>
                         <div>
                            <Button cls='plain' size='small' onClick={()=>requestThirdParty('facebook')}>Request</Button>
                        </div>
                    </div>

                    <div className="connect-account-option coming-soon">
                        <div><LinkedInIcon /></div>
                         <div className="flex-1">
                            <Text>LinkedIn</Text>
                         </div>
                         <div>
                            <Button cls='plain' size='small' onClick={()=>requestThirdParty('linkedin')}>Request</Button>
                        </div>
                    </div>

                    <div className="flex-1"></div>
                </div>
            </div>

            {dataHandlingOpen && <DataHandlingModal onClose={() => setDataHandlingOpen(false)} />}
            {safeguardsOpen && <SafeguardsModal onClose={() => setSafeguardsOpen(false)} />}
        </OnboardingWrapper>
    );
}

export default inject('OnboardingStore')(observer(Connect));
