import React from 'react';

import { Text, HeadText } from '../../Typography';

export const BestResultImg = ({ cls }) => (
    <img src="/tour.starttask.gif" alt="Start a task or ask a question" className={cls} />
);

export const BestResultContent = ({ cls }) => {
    return (
        <div className={`flex flex-col items-center ${cls}`}>
            <HeadText fontSize='xs'>Get Personalized Results</HeadText>

            <Text fontSize='s' fontWeight='light' cls='mt-4 mb-8 text-center'>
                Adapter shows you how it understood your request, then it finds and ranks 
                results based on your individual preferences. Use it to book hotels, flights, and restaurants quickly.
            </Text>
        </div>
    );
}