import React from "react";
import config from "config";
import { observer, inject } from "mobx-react";
import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";
import withPostHog from "hooks/withPosthog";

import {
  AtlasIcon,
  IntentionsIcon,
  LogoutIcon,
  MenuIcon,
  SettingsIcon,
  CloseIcon
} from "../Icon";
import ActionLog from "pages/intentions/common/ActionLog";

import './LeftNav.scss';

const NavLink = ({ onClick, text, Icon, color }: any) => (
  <p className="adapter-navlink" onClick={onClick}>
    <Icon title={text} color={color} />
    <a href="#">{text}</a>
  </p>
);

interface LeftNavOptions {
  AuthStore?: any;
  OnboardingStore?: any;
  userEvents?: any;
}

interface LeftNavProps extends LeftNavOptions, WithAuth0Props { };

class LeftNav extends React.Component<LeftNavProps, {}> {
  state = {
    isOpen: true
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.resize();
  }

  resize = () => {
    this.setState({ isOpen: window.innerWidth >= 768 });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  toggleOpen = () => {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
  }

  handleSignout = () => {
    const { AuthStore, OnboardingStore, userEvents } = this.props;

    AuthStore.logout().then(() => {
      const auth0LogoutUrl = `https://${window.REACT_APP_AUTH0_DOMAIN ?? config.auth0.domain}/v2/logout?client_id=${window.REACT_APP_AUTH0_CLIENT_ID ?? config.auth0.clientId}&returnTo=${encodeURIComponent(window.location.origin)}`;
      if (userEvents) {
        userEvents?.posthog.reset();
      }
      window.location.href = auth0LogoutUrl;
    }).catch((error) => {
      console.error("An error occurred during logout");
    });

  };

  render() {
    const { isOpen } = this.state;

    if (!isOpen) {
      return (
        <div className="closeBtnWrapper">
          <MenuIcon
            onClick={this.toggleOpen}
            style={{ cursor: 'pointer' }}
            color="#000"
          />
        </div>
      );
    }

    return (
      <>
        {isOpen && (
          <div className="closeBtnWrapper">
            <CloseIcon
              onClick={this.toggleOpen}
              style={{ cursor: 'pointer' }}
              color="#000"
            />
          </div>
        )}

        <div className={`adapter-leftnav ${isOpen ? 'open' : ''}`}>
          <img
            src="/adapter-text-logo.svg"
            alt=""
            style={{ width: '214px', cursor: 'pointer' }}
            onClick={() => window.location.href = '/dashboard'}
          />

          <NavLink text="Intentions" Icon={IntentionsIcon} onClick={() => { window.location.href = "/dashboard"; }} />

          <NavLink text="Atlas" Icon={AtlasIcon} onClick={() => { window.location.href = "/atlas"; }} />

          <NavLink text="Settings" Icon={SettingsIcon} onClick={() => { window.location.href = "/settings/profile"; }} />

          <NavLink text="Logout" Icon={LogoutIcon} onClick={this.handleSignout} />

          <div style={{ borderBottom: '1px solid #EAECF0' }}></div>

          <ActionLog />
        </div>
      </>
    );
  }
}

export default withPostHog(inject("AuthStore", "OnboardingStore")(observer(withAuth0(LeftNav))));
