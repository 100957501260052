import React from 'react';

import './Steps.scss';

const Steps = ({ currentStep = 0, totalSteps = 0 }) => {
    const assignClassname = (stepNumber) => {
        if (stepNumber > currentStep) {
            return 'incompleteStep';
        } else if (currentStep === stepNumber) {
            return 'activeStep';
        }

        return 'completedStep';
    }

    let steps = [];

    for (let i=0; i<totalSteps; i++) {
        steps.push(
            <li key={i} className={assignClassname(i)}>&nbsp;</li>
        );
    }

    return (
        <div className="adapter-steps">
            <ol>
                {steps}
            </ol>
        </div>
    );
};

export default Steps;